import { gql } from '@apollo/client'

/*
 * This mutation is responsible for posting the
 * Auth Code (received from the Auth service)
 * to the Onboarded in order to check its validity
 */

const LOGIN_BY_AUTH_CODE = gql`
  mutation LoginByAuthCode ($accessCode: String!, $codeVerifier: String!) {
    loginByAuthCode(accessCode: $accessCode, codeVerifier: $codeVerifier) {
      login
      role
    }
  }
`

export default LOGIN_BY_AUTH_CODE
