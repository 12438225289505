/*
 * This service is responsible for the initializing the initial connection to
 * the main Authentication service (In our case - Genesis - Admin PCI console).
 */

import generatePKCECodeChallenge from '@utils/external-authentication/pkceHelper'

const ExternalAuthentication = {
  redirect: () => {
    console.warn('[ExternalAuthentication.redirect]')

    generatePKCECodeChallenge().then(({ codeChallenge }) => {
      global.location.replace(`
        ${Settings.OAUTH_AUTHENTICATION_URL}?
        client_id=${Settings.OAUTH_CLIENT_ID}&
        response_type=${Settings.OAUTH_RESPONSE_TYPE}&
        redirect_uri=${Settings.OAUTH_INTERNAL_REDIRECT_URI}&
        scope=${Settings.OAUTH_SCOPE}&
        code_challenge=${codeChallenge}&
        code_challenge_method=S256
      `.replace(/\s+/g, ''))
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error generating PKCE Code Challenge:', error)
    })
  }
}

export default ExternalAuthentication
